<template>
  <v-row align="center">
    <v-dialog v-model="dialog" persistent max-width="600">
      <v-card>
        <v-card-title>
          <span class="headline">{{ title }} Semester and year</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form lazy-validation autocomplete="off">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="semester.title"
                    :error="$v.semester.title.$error"
                    hide-details
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Title <span class="text-danger">*</span>
                    </template>
                  </v-text-field>
                  <span class="text-danger" v-if="$v.semester.title.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12">
                  <v-autocomplete
                    v-model="semester.program_ids"
                    :items="programs"
                    item-text="title"
                    item-value="id"
                    hide-details
                    chips
                    multiple
                    clearable
                    small-chips
                    deletable-chips
                    :error="$v.semester.program_ids.$error"
                    outlined
                    dense
                  >
                    <template v-slot:label>
                      Program <span class="text-danger">*</span>
                    </template>
                    <template v-slot:prepend-item>
                      <v-list-item ripple @click="allSelectPrograms">
                        <v-list-item-action>
                          <v-icon
                            :color="
                              semester.program_ids > 0 ? 'indigo darken-4' : ''
                            "
                          >
                            {{ iconProgram }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Select all
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-1"></v-divider>
                    </template>
                    <template v-slot:no-data>
                      <div class="no-data-auto-complete text-center">
                        No data available
                      </div>
                    </template>
                  </v-autocomplete>
                  <span
                    class="text-danger"
                    v-if="$v.semester.program_ids.$error"
                    >This Field is required</span
                  >
                </v-col>

                <v-col cols="12">
                  Status
                  <v-switch
                    v-model="semester.is_active"
                    :label="semester.is_active ? 'Yes' : 'No'"
                    outlined
                    dense
                  ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="createOrUpdate">
            {{ edit ? "Update" : "Save" }}
          </v-btn>
          <v-btn color="red darken-1" dark @click="closeDialog"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import SemesterService from "@/service/semester-and-year/SemesterService";
import ProgramService from "@/service/program/ProgramService";

const semester = new SemesterService();
const program = new ProgramService();

export default {
  name: "CreateAndUpdate",
  data() {
    return {
      dialog: false,
      edit: false,
      programs: [],
      errors: [],
      semester: {
        title: "",
        program_ids: [],
        is_active: true
      }
    };
  },
  validations: {
    semester: {
      title: { required },
      program_ids: { required }
    }
  },
  mounted() {
    this.getAllPrograms();
  },
  computed: {
    title() {
      if (this.edit) {
        return "Edit";
      } else {
        return "Create";
      }
    },
    allSelectProgram() {
      return this.semester.program_ids.length === this.programs.length;
    },
    selectProgram() {
      return this.semester.program_ids.length > 0 && !this.allSelectProgram;
    },
    iconProgram() {
      if (this.allSelectProgram) return "mdi-close-box";
      if (this.selectProgram) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    allSelectPrograms() {
      this.$nextTick(() => {
        if (this.allSelectProgram) {
          this.semester.program_ids = [];
        } else {
          this.semester.program_ids = [];
          this.programs.forEach(score => {
            this.semester.program_ids.push(score.id);
          });
        }
      });
    },
    getAllPrograms() {
      program
        .all()
        .then(response => {
          this.programs = response.data.data;
        })
        .catch(() => {});
    },
    openDialog() {
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    editDialog(semester) {
      this.dialog = true;
      this.edit = true;
      this.resetForm();
      this.semester = semester;
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.$v.$reset();
      this.semester = {
        title: "",
        program_ids: [],
        is_active: true
      };
    },
    createOrUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 6000);
      } else {
        if (this.edit) {
          semester
            .update(this.semester.id, this.semester)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Semester and year updated successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        } else {
          semester
            .store(this.semester)
            .then(response => {
              if (response.data.status === "OK") {
                this.$snotify.success("Semester and year created successfully");
                this.$emit("refresh");
                this.closeDialog();
              }
            })
            .catch(error => {
              this.errors = error.response.data.errors;
            });
        }
      }
    }
  }
};
</script>
