<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Semester And Year</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\
                  Semester And Year
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <a
                    @click="createSemester()"
                    class="btn btn-primary font-weight-bolder font-size-sm"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-white"></span>
                    Add Semester
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div
              class="search-wrapper border-outline mb-5 px-3 py-2 d-sm-flex justify-content-start"
            >
              <v-col cols="12" md="3">
                <v-text-field
                  label="Title"
                  v-model="search.title"
                  clearable
                  outlined
                  dense
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-autocomplete
                  label="Program"
                  v-model="search.program_id"
                  :items="programs"
                  item-text="title"
                  item-value="id"
                  clearable
                  outlined
                  dense
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="search.status"
                  label="Status"
                  :items="status"
                  item-text="name"
                  item-value="value"
                  outlined
                  dense
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <button
                  class="btn btn-primary w-35 float-right"
                  @click="searchSemester"
                >
                  Search
                </button>
              </v-col>
            </div>

            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">
                      <strong>Title</strong>
                    </th>
                    <th class="px-3"><strong>Programs</strong></th>
                    <th class="px-3"><strong>Status</strong></th>
                    <th class="pr-3 text-center"><strong>Action</strong></th>
                  </tr>
                </thead>
                <template v-if="semesters.length > 0">
                  <draggable
                    class="w-100"
                    v-model="semesters"
                    @change="sort"
                    @start="drag = true"
                    tag="tbody"
                    @end="drag = false"
                  >
                    <tr v-for="(item, index) in semesters" :key="index">
                      <td class="px-2">
                        <a
                          @click="editSemester(item)"
                          class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg"
                        >
                          <i class="fa fa-sort"></i>
                          <span class="ml-3">{{ item.title }}</span>
                        </a>
                      </td>
                      <td class="px-2">
                        <ul class="ml-5" v-if="item.programs">
                          <li
                            class="ml-2"
                            v-for="(program, index) in item.programs"
                            :key="index"
                          >
                            {{ program }}
                          </li>
                        </ul>
                        <span v-else>--</span>
                      </td>
                      <td class="px-2">
                        <span
                          class="badge text-lg"
                          v-bind:class="{
                            'badge-success': item.is_active,
                            'badge-danger': !item.is_active
                          }"
                        >
                          {{ item.is_active ? "Active" : "In Active" }}
                        </span>
                      </td>

                      <td class="pr-0 px-2 text-center">
                        <template>
                          <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                            style="padding: 0"
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-175px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  @click="editSemester(item)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="blue darken-2"
                                      >fas fa-edit</v-icon
                                    >
                                  </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a
                                  @click="deleteSemester(item.id)"
                                  class="navi-link"
                                >
                                  <span class="navi-icon">
                                    <v-icon color="red">fas fa-trash</v-icon>
                                  </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </template>
                      </td>
                    </tr>
                  </draggable>
                </template>
                <template v-else>
                  <tr>
                    <td class="text-center" colspan="5">
                      <h5>No Data Found</h5>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                v-if="semesters.length > 0"
                class="pull-right mt-7"
                @input="getAllSemesters"
                v-model="page"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>

          <create-and-update
            ref="create-and-update"
            @refresh="getAllSemesters"
          ></create-and-update>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import draggable from "vuedraggable";
import SemesterService from "@/service/semester-and-year/SemesterService";
import CreateAndUpdate from "@/view/pages/semester-and-year/CreateAndUpdate.vue";
import ProgramService from "@/service/program/ProgramService";

const semester = new SemesterService();
const program = new ProgramService();

export default {
  components: {
    CreateAndUpdate,
    draggable
  },
  data() {
    return {
      drag: false,
      loading: false,
      isSorting: false,
      total: null,
      perPage: null,
      page: null,
      semesters: [],
      programs: [],
      status: [
        { name: "Active", value: "1" },
        { name: "Inactive", value: "0" }
      ],
      search: {
        title: "",
        program_id: "",
        status: ""
      }
    };
  },
  mounted() {
    this.getAllSemesters();
    this.getAllPrograms();
  },
  methods: {
    getAllPrograms() {
      program
        .all()
        .then(response => {
          this.programs = response.data.data;
        })
        .catch(() => {});
    },
    sort() {
      this.isSorting = true;
      semester
        .sort(this.semesters)
        .then(response => {
          if (response.data.status === "OK") {
            this.isSorting = false;
            this.$snotify.success("Semester And Year sorted !!");
            this.getAllSemesters();
          }
        })
        .catch(() => {
          this.isSorting = false;
          this.$snotify.error("Oops something went wrong");
        });
    },
    createSemester() {
      this.$refs["create-and-update"].openDialog();
    },
    editSemester(semester) {
      this.$refs["create-and-update"].editDialog(semester);
    },
    getAllSemesters() {
      semester
        .paginate(this.search, this.page)
        .then(response => {
          this.semesters = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
        })
        .catch(() => {});
    },
    searchSemester() {
      this.getAllSemesters();
    },
    deleteSemester(item) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            semester
              .delete(item)
              .then(response => {
                if (response.data.status === "OK") {
                  this.getAllSemesters();
                  this.$snotify.success(
                    "Semester And Year Deleted Successfully "
                  );
                }
              })
              .catch(() => {
                this.$snotify.error("Oops something went wrong");
              });
          }
        }
      });
    }
  }
};
</script>
